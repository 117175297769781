import { handleResponse, requestOptions } from '@/helpers';
import axios from "axios";

export const gameStateService = {
  getState,
  clearState,
  saveState
};

async function getState(gamingServiceEndpoint,gameName,sessionKey){
  console.log("--------------------------getState-----------------------")
  console.log(gamingServiceEndpoint)
  let response = await axios.request(requestOptions.get(`${gamingServiceEndpoint}/developer/internal/server-info/${gameName}/${sessionKey}`))
  return response

  // await axios.get(`${this.gamingServiceEndpoint}/developer/internal/server-info/${this.gameName}/${this.sessionKey}`)
  //   .then(response => {
  //     if (response.status === 200 && response.data) {
  //       this.gameState = response.data
  //       this.editableGameState = ''
  //     }
  //     return response
  //   })
  //   .catch(() => {

  //   })
}

async function clearState(gamingServiceEndpoint,gameName,sessionKey){
  let response = await axios.request(requestOptions.delete(`${gamingServiceEndpoint}/developer/internal/server-info/${gameName}/${sessionKey}`))
  return response

  // await axios.delete(`${this.gamingServiceEndpoint}/developer/internal/server-info/${this.gameName}/${this.sessionKey}`)
  //   .then(response => {
  //     if (response.status === 200) {
  //       this.gameState = ''
  //       this.editableGameState = ''

  //       this.closeModal()
  //     }
  //   })
  //   .catch(() => {

  //   })
}


async function saveState(gamingServiceEndpoint,gameName,sessionKey, editableGameState){
  let response = await axios.request(requestOptions.put(`${gamingServiceEndpoint}/developer/internal/server-info/${gameName}/${sessionKey}`,{
    state: JSON.parse(editableGameState)
  }))
  return response

  //   await axios.put(`${this.gamingServiceEndpoint}/developer/internal/server-info/${this.gameName}/${this.sessionKey}`, {
  //     state: JSON.parse(this.editableGameState)
  //   })
  //     .then(response => {
  //       if (response.status === 200) {
  //         this.gameState = this.editableGameState
  //         this.editingState = false
  //         this.editableGameState = ''
  //         this.closeModal()
  //       }
  //     })
  //     .catch(() => {
  //     })
}